import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authservice: AuthService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available

        let currentUser = this.authservice.currentUserValue;
       // console.log(currentUser); 
        if (currentUser != undefined && currentUser.authToken) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `${currentUser.authToken}`
                }
            });
        }
       
        return next.handle(request);
       


    }
}