// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v723demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api',
  // BASE_URL:'http://178.62.240.45/api/v1'
  // BASE_URL:'http://192.168.43.5:7000/api/v1',
  // IMGURL:'http://192.168.43.5:7000/api/v1/upload/productsImage/'
  BASE_URL:'https://api.spraykart.in/api/v1',
  IMGURL:'https://api.spraykart.in/api/v1/upload/productsImage/'
};
// http://178.62.240.45/api/v1/documentation

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
